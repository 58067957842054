export const GridEventColumns = [
  {
    key: 'date',
    label: 'Date, Time, Time Zone',
  },
  {
    key: 'eventType',
    label: 'Events',
  },
  {
    key: 'performedBy',
    label: 'Performed By',
  },
  {
    key: 'description',
    label: 'Description',
  },
];

export interface EventsColumnData {
  date: string;
  eventType: string;
  performedBy: string;
  description: string;
}
