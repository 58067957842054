import { Injectable } from '@angular/core';
import { Action, State, Store } from '@ngxs/store';
import { IAccountsInfoResetStateModel } from 'src/app/features/model/sm-accounts';
import {
  defaultAccountsAddressInfoData,
  defaultAccountsBusinessInfoData,
  defaultAccountsDetailData,
  defaultAccountsGovernmentInfoData,
  defaultAccountsInformationData,
} from '../accounts.config';
import {
  SaveAccountsDetail,
  SaveAddressDetails,
  SaveBusinessDetails,
  SaveGovernmentDetails,
} from './accounts.action';

// Reset action
export class AccountsResetAllStates {
  static readonly type = '[Accounts] Reset All States';
}
@State<IAccountsInfoResetStateModel>({
  name: 'accountsResetStates',
  defaults: {
    data: defaultAccountsInformationData(),
  },
})
@Injectable()
export class AccountsResetAllState {
  constructor(private readonly store: Store) {}

  @Action(AccountsResetAllStates)
  resetAllStates() {
    this.store.dispatch(new SaveAccountsDetail(defaultAccountsDetailData()));
    this.store.dispatch(
      new SaveAddressDetails(defaultAccountsAddressInfoData())
    );
    this.store.dispatch(
      new SaveBusinessDetails(defaultAccountsBusinessInfoData())
    );
    this.store.dispatch(
      new SaveGovernmentDetails(defaultAccountsGovernmentInfoData())
    );
  }
}
