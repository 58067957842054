import { Action, State, StateContext } from '@ngxs/store';
import {
  FetchUsersList,
  ResetUsersList,
  SaveDepartmentDetails,
  FetchSelectedUserById,
  FetchUsersInformationListSuccess,
  ResetUsersInformationList,
} from './users.action';
import { IDepartment, UsersColumnData } from 'src/app/features/model/sm-users';
import { Injectable } from '@angular/core';
import { defaultDepartmentGridData } from '../user-management.config';
import { updateState } from 'src/app/shared/utility/store.utility';

export interface UsersListStateModel {
  user: UsersColumnData[];
  totalData: number;
}

@Injectable()
@State<UsersListStateModel>({
  name: 'usersList',
  defaults: {
    user: [],
    totalData: 0,
  },
})
export class UsersListState {
  @Action(FetchUsersList)
  FetchHawbsList(
    ctx: StateContext<UsersListStateModel>,
    action: FetchUsersList
  ) {
    const payload = action.payload;
    if (payload.data?.user && payload.data?.totalRecords) {
      updateState(ctx, {
        user: payload.data.user,
        totalData: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        user: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetUsersList)
  ResetUserList(ctx: StateContext<UsersListStateModel>) {
    updateState(ctx, {
      user: [],
      totalData: 0,
    });
  }
}

// Department Information Section
export interface DepartmentsStateModel {
  departments: IDepartment[];
}
@State<DepartmentsStateModel>({
  name: 'usersDepartmentsInfo',
  defaults: {
    departments: defaultDepartmentGridData,
  },
})
@Injectable()
export class UsersDepartmentsInfoState {
  @Action(SaveDepartmentDetails)
  saveBusinessDetailsData(
    ctx: StateContext<DepartmentsStateModel>,
    action: SaveDepartmentDetails
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      departments: action.payload,
    });
  }
}

// User CRUD
export interface UserInformationStateModel {
  userInformation: any;
  usersInformationList: any[];
}

@State<UserInformationStateModel>({
  name: 'userInformation',
  defaults: {
    userInformation: {},
    usersInformationList: [],
  },
})
@Injectable()
export class UserInformationState {
  @Action(FetchUsersInformationListSuccess)
  fetchUsersInformationListSuccess(
    { getState, setState }: StateContext<UserInformationStateModel>,
    { users }: FetchUsersInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      usersInformationList: users,
    });
  }

  @Action(FetchSelectedUserById)
  FetchSelectedUserById(
    ctx: StateContext<UserInformationStateModel>,
    action: FetchSelectedUserById
  ) {
    const state = ctx.getState();
    const selectedUser = state.usersInformationList.find(
      element => element.userId === action.userId
    );

    updateState(ctx, {
      userInformation: selectedUser || {},
    });
  }

  @Action(ResetUsersInformationList)
  resetUserInfo(ctx: StateContext<UserInformationStateModel>) {
    updateState(ctx, {
      userInformation: {},
      usersInformationList: [],
    });
  }
}
