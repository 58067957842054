import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import { URLConstants } from 'src/app/shared/utility/url-constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public requiredUserInfoData = new BehaviorSubject<any>({});
  public isUserPrevSummary = new BehaviorSubject<any>('');
  public isFromUserPageSummary = new BehaviorSubject<string>('');
  public isDeletedDepartmentsData = new BehaviorSubject<string>('');

  constructor(private readonly baseService: BaseService) {}

  public setRequiredDataForUserInfoPage(data: any): void {
    this.requiredUserInfoData.next(data);
  }

  public isUserSummaryPrevSearch(data: string) {
    this.isUserPrevSummary.next(data);
  }

  public setUserPageFrom(data: string) {
    this.isFromUserPageSummary.next(data);
  }

  public setDeletedDepartmentsData(data: string) {
    this.isDeletedDepartmentsData.next(data);
  }

  public createUser(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'PUT',
      URLConstants.createUser,
      data,
      'json'
    );
  }

  public updateUser(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.updateUser,
      data,
      'json'
    );
  }

  public deleteUser(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteUser,
      data,
      'json'
    );
  }

  public fetchUser(userId: number): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.fetchUser + userId,
      '',
      'json'
    );
  }

  public getDepartmentRole(): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.getDepartmentAndRole,
      '',
      'json'
    );
  }

  public userBasicSearch(payload: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.basicSearchUser,
      payload,
      '',
      'json'
    );
  }

  public addorUpdateDepartment(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.addOrUpdateDepartment,
      data,
      'json'
    );
  }

  public deleteDepartment(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteDepartment,
      data,
      'json'
    );
  }

  public fetchUserLoginHistory(userId: number): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.userLoginHistory + userId,
      '',
      'json'
    );
  }
  
  public sendEmail(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.sendEmail,
      data,
      'json'
    );
  }
}
